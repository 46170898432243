import React, { useEffect } from 'react'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { LinkHubspot } from './LinkHubspot'
import { Button } from './Button'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReuseImgTag } from './ReuseImgTag'

const CardWapper = styled.div`
  border-radius: 32px;
  /* .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.2);
    height: 500px;
    border-radius: 32px;
  } */
  @media (max-width: 1391px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .container {
    padding: 2px 16px;
  }
  .button {
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
  }
  h2 {
    /* color: #34a1d5; */
    color: #047cc2;
    text-align: left;
    padding-left: 1rem;
    padding-bottom: 2rem;
    /* background: -webkit-linear-gradient(45deg, #0f2137, #0f2137); */
  }

  p {
    margin-bottom: 40px;
    /* color: rgba(19, 20, 20, 0.9); */
    color: #071741;
    padding-left: 0rem;
    opacity: 0.85;
    @media (max-width: 1391px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  img {
    @media (max-width: 1391px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  button:focus {
    outline: none;
  }
  .ListType {
    list-style-type: none;
    text-decoration: none;
    list-style: none;
    display: flex;
    justify-content: center;
  }
`

export const Card = (props?: any) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <CardWapper>
      <div className={props.CardSize} id={props.id}>
        <motion.div
          className={props.card}
          ref={ref}
          animate={controls}
          initial='hidden'
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 25 },
          }}
          transition={{ ease: 'easeOut', duration: 1.25, delay: 0.35 }}>
          <div className={props.CircleImage}>
            {/* <img src={props.ImagePath} alt='CardLogo' className={props.CardImage} /> */}
            <ReuseImgTag ImagePath={props.ImagePath} AltName={props.altName} ImageStyle={props.CardImage} />
          </div>
          <div className={props.container}>
            {/* <h2 className={props.HeadingStyle}>{props.heading}</h2> */}
            <ReUseHTwotag HeadingStyle={props.HeadingStyle} Heading={props.heading} />
            {/* <p className={props.paraStyle}>{props.para}</p> */}
            <ReUsePtag para={props.para} paraStyle={props.paraStyle} />
            <LinkHubspot to={props.URL} state={{ scroll: props.scrollPath }} className='ListType'>
              <Button cta={props.ButtonName} className={props.Toggle} onClick={props.action}>
                {props.ButtonName}
              </Button>
            </LinkHubspot>
          </div>
        </motion.div>
      </div>
    </CardWapper>
  )
}

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { Button } from './Button'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReuseImgTag } from './ReuseImgTag'
import { LinkHubspot } from './LinkHubspot'

const RotateCardWapper = styled.div`
  /* .mainContainer {

  } */
  .theCard {
    position: relative;
    width: 300px;
    height: 370px;
    box-shadow: 0 0 20px 0px #9cdfff;
    border-radius: 32px;
    margin-bottom: 20%;
    background-color: transparent;
    transition: all 0.4s ease-in-out;
    transform-style: preserve-3d;
    border-radius: 32px;
    background-color: transparent;
  }
  .theCard:hover {
    transform: rotateY(180deg);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .theFornt {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    color: #333;
    border-radius: 32px;
  }
  .theBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    color: #333;
    transform: rotateY(180deg);
    background-color: #047cc2;
    border-radius: 32px;
    margin: auto;
    padding: 2rem 1rem 2rem 1rem;
  }
  h2 {
    /* color: rgb(15, 33, 55); */
    color: #047cc2;
  }
  p {
    /* color: rgba(52, 61, 72, 0.8); */
    color: #fff;
  }
`

export const RotateCard = (props: any) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <RotateCardWapper>
      <div className="theCard" id={props.id}>
        <div className="theFornt">
          {/* <img src={props.ImagePath} alt={props.AltName} className={props.ImageStyle} /> */}
          <ReuseImgTag ImagePath={props.ImagePath} AltName={props.AltName} ImageStyle={props.ImageStyle} />
          {/* <h2 className={props.HeadingStyle}>{props.Heading}</h2> */}
          <ReUseHTwotag HeadingStyle={props.HeadingStyle} Heading={props.Heading} />
        </div>
        <div className="theBack">
          {/* <p className={props.ParaStyle}>{props.para}</p> */}
          <ReUsePtag para={props.para} paraStyle={props.ParaStyle} />
          {/* <LinkHubspot to='/solutions' state={{ scroll: props.scrollPath }} className={props.ButtonDisplay}> */}
          <LinkHubspot to="https://share.hsforms.com/1wzfrY5DmSsa2666ecHtWvw4702y" className={props.ButtonDisplay}>
            <span className="sr-only">Jump to packages</span>
            <Button cta="Get Started Now" label="Get Started Now" type="submit" className="demobutton" />
          </LinkHubspot>
        </div>
      </div>
    </RotateCardWapper>
  )
}

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import ProfessionalData from '../Data/ProfessionalData.json'
import { Card } from './Card'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'

const ProfessionalWapper = styled.div`
  margin: auto;
  max-width: 1920px;
  padding-bottom: 0rem;
  .ProfessionalCardSize {
    min-width: 400px;
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 32px;
    height: 700px;
    @media (max-width: 770px) {
      height: 600px;
      margin-top: 10%;
    }
    @media (max-width: 420px) {
      height: 500px;
      margin-top: 15%;
    }
  }
  /* .ProfessionalCardSize:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.2);
    height: 700px;
    border-radius: 32px;
  } */
  .ProLeft {
    margin-left: 9%;
    margin-top: 5%;
  }
  .headingPro {
    color: #047cc2;
    margin-top: 5%;
    padding-bottom: 3%;
  }
  .paraPro {
    color: #071741;
    opacity: 0.85;
    max-width: 75%;
    @media (max-width: 1391px) {
      max-width: 90%;
    }
  }
  .CardPro {
    margin-top: 8%;
    display: flex;
    padding: 10px;
    flex-flow: wrap;
    justify-content: space-around;
    max-width: 1920px;
  }
  .imageCard {
    width: 100%;
    height: 330px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    border-radius: 32px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    @media (max-width: 540px) {
      width: 100%;
      height: auto;
    }
  }
  .ProfessionalbuttonCenter {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .ProfessionalButtonMore {
    background: linear-gradient(90deg, #16b4ff, #644eff, #16b4ff);
    background-size: 400%;
    border-radius: 30px;
    border: none;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    padding: 0 20px;
    height: 50px;
    color: #fff;
    text-align: right;
    line-height: 50px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    color: #fff;
  }
  .ProfessionalButtonMore:hover {
    background: linear-gradient(90deg, #fff, #fff, #fff);
    color: #047cc2;
  }
  .headingStyle {
    display: flex;
    justify-content: center;
  }
`

const CardData = [
  {
    id: 'Differentiators',
    imagePath: 'https://storage.googleapis.com/raindroppublic/website_data/DifferentiatorsPro.jpg',
    heading: 'Differentiators',
    Cardpara:
      'Take the leverage of our extensive industry contacts, alliances, and technology knowledge for long term cost avoidance.',
    buttonName: 'Read more',
    ShiftUrl: '/services',
    scrollPath: 'Differentiators',
    AltName: 'Brand differentiators strategy',
  },
  {
    id: 'Services',
    imagePath: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    heading: 'Our Services',
    Cardpara:
      'Get the right fit configuration from planning, procurement, operations to sourcing management through Raindrop platform.',
    buttonName: 'Read more',
    ShiftUrl: '/services',
    scrollPath: 'Services',
    AltName: 'Explore our services',
  },
]
export const Professional = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <ProfessionalWapper>
      {ProfessionalData.map((data, index) => {
        return (
          <motion.div
            className='ProLeft'
            key={index}
            ref={ref}
            animate={controls}
            initial='hidden'
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 25 },
            }}
            transition={{ ease: 'easeOut', duration: 1.25, delay: 0.35 }}>
            {/* <h2>{data.title}</h2> */}
            <ReUseHTwotag Heading={data.title} HeadingStyle='headingPro' />
            {/* <p className='paraPro'>{data.para}</p> */}
            <ReUsePtag para={data.para} paraStyle='paraPro' />
          </motion.div>
        )
      })}
      <div className='CardPro'>
        {CardData.map((item, index) => {
          return (
            <div key={index}>
              <Card
                scrollLink={item.id}
                ImagePath={item.imagePath}
                heading={item.heading}
                para={item.Cardpara}
                ButtonName={item.buttonName}
                scrollPath={item.scrollPath}
                HeadingStyle='headingStyle'
                URL={item.ShiftUrl}
                Toggle='ProfessionalButtonMore'
                CardImage='imageCard'
                buttonCenter='ProfessionalbuttonCenter'
                CardSize='ProfessionalCardSize'
                altName={item.AltName}
              />
            </div>
          )
        })}
      </div>
    </ProfessionalWapper>
  )
}

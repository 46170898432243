import React from 'react'
import styled from 'styled-components'
import { RotateCard } from './RotateCard'
import HomeByRoleCardData from '../Data/HomeByRoleCardData.json'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReUsePtag } from './ReUsePtag'

const AppHomeByRoleCardWapper = styled.div`
  padding-top: 0rem;
  padding-bottom: 0rem;
  max-width: 1920px;
  margin: auto;
  .CardCaniter {
    margin-top: 5%;
    display: flex;
    margin-bottom: 5%;
    padding: 10px;
    flex-flow: wrap;
    justify-content: space-evenly;
    max-width: 1820px;
    text-align: center;
    margin: auto;
  }
  .CardImage {
    width: auto;
    height: 100px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding-top: 5%;
  }
  .CardPara {
    text-align: left;
    padding-bottom: 2rem;
  }
  .UpperPara {
    text-align: left;
    padding: 0rem 5rem 3rem 5rem;
    @media (max-width: 1391px) {
      padding: 0rem 1rem 3rem 1rem;
    }
  }
  .upperHeading {
    padding-top: 3rem;
    padding-bottom: 3%;
    text-align: center;
    margin: 0;
  }
  .HomeByRoleCard-Container {
    /* background-color: #f3f4f6; */
    padding: 10px 0px 80px 0px;
  }
`

export const AppHomeByRoleCard = () => {
  return (
    <AppHomeByRoleCardWapper>
      <div className="HomeByRoleCard-Container">
        <div className="Header-ByRole">
          <ReUseHTwotag Heading="By Role" HeadingStyle="upperHeading" />
          <ReUsePtag
            para="With Raindrop, procurement leaders to executives have a platform that ensures procurement’s goals and processes are directly aligned with deriving business value and cost savings."
            paraStyle="UpperPara"
          />
        </div>
        <div className="CardCaniter">
          {HomeByRoleCardData.map((data, index) => {
            return (
              <div key={index} className="">
                <RotateCard
                  Heading={data.title}
                  para={data.para}
                  ImagePath={data.ImagePath}
                  AltName={data.AltName}
                  ParaStyle="CardPara"
                  ImageStyle="CardImage"
                  HeadingStyle="CardHeading"
                  scrollPath={data.scrollPath}
                />
              </div>
            )
          })}
        </div>
      </div>
    </AppHomeByRoleCardWapper>
  )
}

import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'
import { LinkHubspot } from './LinkHubspot'

const AboutWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .upperSection {
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
    flex-wrap: wrap;
  }
  .aboutParaStyle {
    max-width: 750px;
  }
  button:focus {
    outline: none;
  }
  .upperImage {
    height: 370px;
    width: auto;
  }
  .ParaSet {
    margin-top: 5%;
  }
  .lowerSection {
    width: 100%;
  }
  .ButtonUpper {
    margin-bottom: 12px;
    display: flex;
    @media (max-width: 480px) {
      flex-direction: column;
      width: 275vw;
    }
  }
  .colorOne {
    /* background-color: #4bacc6; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: all 0.5s ease;
    border-radius: 42px;
    margin-left: 12px;
    color: #047cc2;
    width: 34%;
    height: 70px;
    line-height: 70px;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    border: none;
    text-align: center;
    list-style-type: none;
    text-decoration: none;
    list-style: none;
    @media (max-width: 480px) {
      font-size: 20px;
    }
    @media (max-width: 420px) {
      font-size: 18px;
    }
  }
  .colorblue:hover {
    background-color: #fff;
    color: #047cc2;
  }
  .colorTwo {
    /* background-color: #8267a3; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: all 0.5s ease;
    border-radius: 42px;
    margin-left: 12px;
    color: #047cc2;
    width: 34%;
    text-decoration: none;
    height: 70px;
    line-height: 70px;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    text-align: center;
    border: none;
    list-style-type: none;
    text-decoration: none;
    list-style: none;
    @media (max-width: 480px) {
      font-size: 20px;
    }
    @media (max-width: 420px) {
      font-size: 18px;
    }
  }
  .colorThree {
    /* background-color: #f79646; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: all 0.5s ease;
    border-radius: 42px;
    margin: 0px 12px 0px 12px;
    color: #047cc2;
    width: 34%;
    text-decoration: none;
    height: 70px;
    line-height: 70px;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    text-align: center;
    border: none;
    list-style-type: none;
    text-decoration: none;
    list-style: none;
    @media (max-width: 480px) {
      font-size: 20px;
    }
    @media (max-width: 420px) {
      font-size: 18px;
    }
  }
  .colorFour {
    /* background-color: #9bbb59; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: all 0.5s ease;
    border-radius: 42px;
    margin-left: 12px;
    color: #047cc2;
    width: 34%;
    text-decoration: none;
    height: 70px;
    line-height: 70px;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    text-align: center;
    border: none;
    list-style-type: none;
    text-decoration: none;
    list-style: none;
    @media (max-width: 480px) {
      font-size: 20px;
    }
    @media (max-width: 420px) {
      font-size: 18px;
    }
  }
  .colorFive {
    /* background-color: #c0504d; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: all 0.5s ease;
    border-radius: 42px;
    margin-left: 12px;
    color: #047cc2;
    width: 34%;
    text-decoration: none;
    height: 70px;
    line-height: 70px;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    text-align: center;
    border: none;
    list-style-type: none;
    text-decoration: none;
    list-style: none;
    @media (max-width: 480px) {
      font-size: 20px;
    }
    @media (max-width: 420px) {
      font-size: 18px;
    }
  }
  .colorSix {
    /* background-color: #34a1d5; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: all 0.5s ease;
    border-radius: 42px;
    margin: 0px 12px 0px 12px;
    color: #047cc2;
    width: 34%;
    text-decoration: none;
    height: 70px;
    line-height: 70px;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    text-align: center;
    border: none;
    list-style-type: none;
    text-decoration: none;
    list-style: none;
    @media (max-width: 480px) {
      font-size: 20px;
    }
    @media (max-width: 420px) {
      font-size: 18px;
    }
  }
  .colorOne:hover {
    cursor: pointer;
    font-size: 24px;
    font-weight: bolder;
    transition: 0.5s ease;
    border-left: 5px solid #047cc2;
  }
  .colorTwo:hover {
    cursor: pointer;
    font-size: 24px;
    font-weight: bolder;
    transition: 0.5s;
    border-left: 5px solid #047cc2;
  }
  .colorThree:hover {
    cursor: pointer;
    font-size: 24px;
    font-weight: bolder;
    transition: 0.5s;
    border-left: 5px solid #047cc2;
  }
  .colorFour:hover {
    cursor: pointer;
    font-size: 24px;
    font-weight: bolder;
    transition: 0.5s;
    border-left: 5px solid #047cc2;
  }
  .colorFive:hover {
    cursor: pointer;
    font-size: 24px;
    font-weight: bolder;
    transition: 0.5s;
    border-left: 5px solid #047cc2;
  }
  .colorSix:hover {
    cursor: pointer;
    font-size: 24px;
    font-weight: bolder;
    transition: 0.5s;
    border-left: 5px solid #047cc2;
  }
`

export const About = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <AboutWapper>
      <motion.div
        className="lowerSection"
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 25 },
        }}
        transition={{ ease: 'easeOut', duration: 0.5, delay: 0.35 }}
      >
        <div className="ButtonUpper">
          <LinkHubspot to="/about" className="colorOne" state={{ scroll: 'TotalSolution' }}>
            Total Solution
          </LinkHubspot>
          <LinkHubspot to="/about" className="colorTwo" state={{ scroll: 'Security' }}>
            Security
          </LinkHubspot>
          <LinkHubspot to="/about" className="colorThree" state={{ scroll: 'ExceptionalService' }}>
            Exceptional Service
          </LinkHubspot>
        </div>
        <div className="ButtonUpper">
          <LinkHubspot to="/about" className="colorFour" state={{ scroll: 'TailoredFit' }}>
            Tailored Fit
          </LinkHubspot>
          <LinkHubspot to="/about" className="colorFive" state={{ scroll: 'Experience' }}>
            Experience
          </LinkHubspot>
          <LinkHubspot to="/about" className="colorSix" state={{ scroll: 'Quality' }}>
            Quality
          </LinkHubspot>
        </div>
      </motion.div>
    </AboutWapper>
  )
}

import React from 'react'
import { LinkHubspot } from './LinkHubspot'
// import { ButtonCompactWithLogo } from './Button'
import { SignUpAltButton } from './SignUpAltButton'

export interface SignUpLandingPageButtonProps {
  label: string
  style?: any
  onClick?: any
}

export const SignUpLandingPageButton = (props: SignUpLandingPageButtonProps) => {
  return (
    <LinkHubspot to="https://share.hsforms.com/1wzfrY5DmSsa2666ecHtWvw4702y" style={props.style}>
      <span className="sr-only">Jump to Signup</span>
      <SignUpAltButton cta={props.label} label={props.label} onClick={props.onClick} />
    </LinkHubspot>
  )
}
